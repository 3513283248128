export class City {
  country?: string;
  id?: number;
  name?: string;
  state?: string;

  fromJson(data: any) {
    this.country = data?.country;
    this.id = data?.id;
    this.name = data?.name;
    this.state = data?.state;
  }

  fromJsonPT(data: any) {
    if (data) {
      this.country = 'Brasil';
      this.id = data?.id;
      this.name = data?.municipio;
      this.state = data?.uf;
    }
  }
}
