import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { ModalController } from '@ionic/angular';
import { AuthConstants } from './config/auth-constants';
import { AccessService } from './services/access/access.service';
import { AuthService } from './services/auth/auth.service';
import { StorageService } from './services/storage/storage.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [ScreenOrientation],
})
export class AppComponent {
  constructor(
    private accessService: AccessService,
    private authService: AuthService,
    private modalController: ModalController,
    private router: Router,
    private storageService: StorageService,
    private screenOrientation: ScreenOrientation,
    private zone: NgZone
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    const biometricAvailable = await this.accessService.isAvailable();
    try {
      await this.screenOrientation?.lock(
        this.screenOrientation.ORIENTATIONS.PORTRAIT
      );
    } catch (error) {
      console.log('ScreenOrientation is not available on this device');
    }

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = 'app.colapp.com.br';
        //const domain = 'localhost';

        // The pathArray is now like ['https://devdactic.com', '/details/42']
        console.log('=====================> DOMAIN DEVICE: ' + event?.url);
        const pathArray = event.url.split(domain);
        console.log('=====================> pathArray: ' + pathArray);

        // Get the last element with pop()
        const appPath = pathArray.pop();
        console.log('=====================> appPath: ' + appPath);
        if (appPath) {
          console.log(
            '=====================>Entrou no IF do appPath: ' + appPath
          );

          this.router.navigateByUrl(appPath);
        }
      });
    });

    if (true) {
      App.addListener('resume', () => {
        this.storageService.get(AuthConstants.APP_RESUME).then(async (res) => {
          const appResume = res;
          if (
            appResume === AuthConstants.APP_RESUME_BIOMETRIC ||
            appResume === AuthConstants.APP_RESUME_GEOLOCATION ||
            appResume === AuthConstants.APP_RESUME_FORGOT ||
            appResume === AuthConstants.APP_RESUME_INDICATION
          ) {
            if (
              appResume !== AuthConstants.APP_RESUME_FORGOT &&
              appResume !== AuthConstants.APP_RESUME_INDICATION
            ) {
              this.storageService.removeStorageItem(AuthConstants.APP_RESUME);
            }
          } else {
            let topModal = await this.modalController.getTop();
            while (topModal) {
              await topModal.dismiss();
              topModal = await this.modalController.getTop();
            }
            this.authService.logout(true);
          }
        });
      });
    }
  }
}
