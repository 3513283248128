import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  isLoading = false;

  constructor(public loadingController: LoadingController) {}

  async showLoader() {
    if (!this.isLoading) {
      this.isLoading = true;
      return await this.loadingController
        .create({
          message: 'Aguarde...',
        })
        .then((a) => {
          a.present().then(() => {
            if (!this.isLoading) {
              a.dismiss();
            }
          });
        });
    }
  }

  async hideLoader() {
    if (this.isLoading) {
      this.isLoading = false;
      return await this.loadingController.dismiss();
    }
  }
}
