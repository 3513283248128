import { CompanyFull } from 'src/app/services/company/model/company-full';
import { formatCNPJ, formatCPF } from 'src/app/utils/document';
import { formatPhone } from 'src/app/utils/phone';
import { Address } from '../../address/model/address';
import { UserBankAccount } from './user-bank-account';
import { UserIndicateds } from './user-indicateds';
import { UserPixKey } from './user-pix-key';
import { UserStatements } from './user-statements';
export class User {
  acceptedTerms?: boolean;
  admin?: boolean;
  address?: Address;
  bankAccount?: UserBankAccount;
  birth?: string;
  companyId?: number;
  createdAt?: Date;
  credits = 0;
  document?: string;
  email?: string;
  emailConfirm?: string;
  firstName?: string;
  hasPasswordTransaction?: boolean;
  id?: number;
  indicateds?: UserIndicateds;
  indicator?: string;
  isCompany = false;
  isPrime = false;
  name?: string;
  onHold?: boolean;
  password?: string;
  passwordConfirm?: string;
  pixKey?: UserPixKey;
  phone?: string;
  plan?: string;
  statements?: UserStatements;
  company?: CompanyFull;

  fromJson(user: any) {
    if (user) {
      this.admin = user?.admin;
      this.company = Object.assign(
        new CompanyFull(),
        user?.companies?.slice()?.shift()
      );
      this.isCompany = user?.companies?.length > 0;
      if (this.company?.disabled) {
        this.company = null;
        this.isCompany = false;
      }
      this.address = new Address();
      this.address.fromJson(user?.address || this.company?.address);
      this.bankAccount = Object.assign(
        new UserBankAccount(),
        user?.BankAccount?.shift()
      );

      this.birth = user?.birth ? user?.birth : new Date();
      this.companyId = this.company?.id;
      this.createdAt = user?.createdAt;
      this.document =
        formatCPF(user?.document) || formatCNPJ(this.company?.cnpj);
      this.email = user?.email;
      this.name = this.formatName(user?.name);
      this.firstName = this.name?.split(' ')?.shift();
      this.hasPasswordTransaction = !!user?.passwordTransaction;
      this.id = parseFloat(user?.id);
      this.indicateds = new UserIndicateds();
      this.indicator = user?.indicator || '';
      this.onHold = user?.onHold;
      this.password = '';
      this.passwordConfirm = '';
      if (user?.pixKey) {
        this.pixKey = Object.assign(new UserPixKey(), user?.pixKey?.shift());
      }
      this.phone = formatPhone(user?.phone);
      this.plan = user?.plan?.toUpperCase() === 'PRIME' ? 'PRIME' : 'FREE';
      this.isPrime = this.plan === 'PRIME';
      this.statements = new UserStatements();
    }
  }

  formatName(name: string): string {
    name.toLowerCase();
    let fullName = '';
    const names = name.split(' ');
    let first = true;
    names.forEach((name) => {
      if (!first) {
        fullName += ' ';
      }
      first = false;
      fullName += name.charAt(0).toUpperCase() + name.slice(1);
    });
    return fullName;
  }
}
