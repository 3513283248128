import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(public toastController: ToastController) {}

  async presentToastError(infoMessage: string, duration = 3000) {
    this.presentToast(infoMessage, 'danger', duration);
  }

  async presentToastSuccess(infoMessage: string, duration = 3000) {
    this.presentToast(infoMessage, 'success', duration);
  }

  async presentToastWarning(infoMessage: string, duration = 3000) {
    this.presentToast(infoMessage, 'warning', duration);
  }

  async presentToast(infoMessage: string, color: string, duration: number) {
    const toast = await this.toastController.create({
      message: infoMessage,
      position: 'top',
      color,
      duration,
    });
    await toast.present();
  }
}
