export class UserBankAccount {
  agency?: string;
  bankId?: string;
  bankName?: string;
  digits?: string;
  id?: number;
  name?: string;
  type?: string;
  userId?: number;
}
