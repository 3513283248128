import { Address } from './../../address/model/address';
import { CompanyCategory } from './company-category';
import { CompanyNiche } from './company-niche';
export class CompanyFull {
  acceptedTerms?: boolean;
  address?: Address;
  birth?: string;
  cnpj?: string;
  cashbackPct?: number;
  category?: CompanyCategory;
  categoryId?: number;
  companyCashbackPct?: number;
  disabled = false;
  email?: string;
  emailConfirm?: string;
  fantasyName?: string;
  fixedCashback?: number;
  fixedCompanyCashback?: number;
  id?: number;
  indicator?: string;
  isSocialEntity = false;
  isSelfEmployedBusiness = false;
  name?: string;
  niche?: CompanyNiche;
  phone?: string;
  password?: string;
  passwordConfirm?: string;
  realCashbackPct?: number;
  realCompanyCashbackPct?: number;
  realFixedCashback?: number;
  realFixedCompanyCashback?: number;

  fromJsonPT(data: any) {
    this.address = new Address();
    this.address.fromJsonPT(data);
    this.birth = this.formatOpenDate(data?.abertura);
    this.cnpj = data?.cnpj;
    this.email = data?.email;
    this.fantasyName = data?.fantasia || data?.nome;
    this.id = parseFloat(data?.id);
    this.name = data?.nome;
    this.phone = this.getPhone(data?.telefone);
  }

  getPhone(phone: string) {
    const phones = phone?.trim()?.split('/');
    return phones[0]?.trim();
  }

  formatOpenDate(dtOpen: string) {
    const [day, month, year] = dtOpen.split('/');
    const data = new Date(`${year}-${month}-${day}`);
    dtOpen = data.toISOString().split('T')[0];
    return dtOpen;
  }
}
