import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthConstants } from 'src/app/config/auth-constants';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  token$ = new BehaviorSubject<any>('');

  constructor(private storageService: StorageService) {}

  getToken() {
    this.storageService.get(AuthConstants.TOKEN).then((res) => {
      this.token$.next(res);
    });
  }
}
