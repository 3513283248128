import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import packageJson from 'package.json';
import { EMPTY, Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alert/alert.service';
import { AuthService } from '../auth/auth.service';
import { LoaderService } from '../loader/loader.service';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public apiUrl;
  public token: any;
  public appVersion: string = packageJson.version;

  constructor(
    private alertService: AlertService,
    private http: HttpClient,
    private injector: Injector,
    private loaderService: LoaderService,
    private tokenService: TokenService
  ) {
    this.apiUrl = environment.apiUrl;
    this.tokenService.token$.subscribe((res: any) => {
      this.token = res;
    });
  }

  post(serviceName: string, data: any) {
    const options = { headers: this.getHeader(), withCredintials: false };
    return this.http
      .post(
        this.apiUrl + serviceName,
        data ? JSON.stringify(data) : null,
        options
      )
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleAuthError(error))
      );
  }

  put(serviceName: string, data: any) {
    const options = { headers: this.getHeader(), withCredintials: false };
    return this.http
      .put(this.apiUrl + serviceName, JSON.stringify(data), options)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleAuthError(error))
      );
  }

  delete(serviceName: string) {
    const options = { headers: this.getHeader(), withCredintials: false };
    return this.http
      .delete(this.apiUrl + serviceName, options)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleAuthError(error))
      );
  }

  get(serviceName: string, params?: HttpParams) {
    const options = {
      headers: this.getHeader(),
      withCredintials: false,
      params,
    };
    return this.http
      .get(this.apiUrl + serviceName, options)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleAuthError(error))
      );
  }

  private handleAuthError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      const authService = this.injector.get(AuthService);
      authService.logout(false);
      this.alertService.showAlertOK(
        'Informação',
        error?.error || 'Ocorreu um erro ao acessar o sistema.'
      );
      this.loaderService.hideLoader();
      return EMPTY;
    } else {
      return throwError(() => error);
    }
  }

  getHeader() {
    return new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Accept', '*/*')
      .set('Content-Type', 'application/json')
      .set('Accept-Encondig', 'gzip, deflate, br')
      .set('X-App-Version', this.appVersion)
      .set('Authorization', this.token?.token || '');
  }
}
