export class AuthConstants {
  public static readonly AUTH = 'userDataKey';
  public static readonly TOKEN = 'tokenKey';
  public static readonly ACTIVATION_EMAIL = 'activationEmail';
  public static readonly APP_RESUME = 'appResume';
  public static readonly APP_RESUME_BIOMETRIC = 'appResumeBiometric';
  public static readonly APP_RESUME_GEOLOCATION = 'appResumeGeolocation';
  public static readonly APP_RESUME_FORGOT = 'appResumeForgot';
  public static readonly APP_RESUME_INDICATION = 'appResumeIndication';
}
