import { Injectable } from '@angular/core';
import {
  BiometryType,
  Credentials,
  NativeBiometric,
} from 'capacitor-native-biometric';

@Injectable({
  providedIn: 'root',
})
export class AccessService {
  constructor() {}

  public async isAvailable(): Promise<boolean> {
    try {
      const result = await NativeBiometric?.isAvailable();
      console.log(
        '======================> isAvailable() - isAvailable: ' +
          result?.isAvailable
      );
      console.log(
        '======================> isAvailable() - biometryType: ' +
          result?.biometryType
      );
      const isFaceID = result?.biometryType == BiometryType.FACE_ID;
      console.log(
        '======================> isAvailable() - isFaceID: ' + isFaceID
      );
      return result?.isAvailable;
    } catch (error) {
      console.log('NativeBiometricWeb: ' + error?.message);
      return false;
    }
  }

  public async verifyIdentity(): Promise<boolean> {
    console.log('======================> verifyIdentity()');
    const verified = await NativeBiometric?.verifyIdentity({
      reason: 'Login fácil com biometria',
      title: 'Login',
    })
      .then(() => true)
      .catch(() => false);

    console.log(
      '======================> verifyIdentity() - verified: ' + verified
    );
    return verified;
  }

  public async getCredentials(): Promise<Credentials> {
    let credentials: Credentials = null;
    try {
      console.log('======================> getCredentials()');
      credentials = await NativeBiometric?.getCredentials({
        server: 'colapp.com.br',
      });
      return credentials;
    } catch (error) {
      console.log(
        '======================> getCredentials() - ERROR: ' + error?.message
      );
      return credentials;
    }
  }

  public async setCredentials(
    username: string,
    password: string
  ): Promise<boolean> {
    const hasBiometric = await this.isAvailable();
    if (!hasBiometric) return null;

    console.log('======================> setCredentials()');
    NativeBiometric?.setCredentials({
      username,
      password,
      server: 'colapp.com.br',
    }).then();
  }

  public async deleteCredentials(): Promise<boolean> {
    console.log('======================> deleteCredentials()');
    const hasBiometric = await this.isAvailable();
    if (!hasBiometric) return null;
    const deleted = NativeBiometric?.deleteCredentials({
      server: 'colapp.com.br',
    })
      .then(() => true)
      .catch(() => false);
    return deleted;
  }
}
