import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private alertController: AlertController) {}

  async showAlertOK(header: string, message: string) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK'],
    });
    await alert.present();
  }

  async showAlertWithConfirm(
    header: string,
    message: string,
    handler: () => void
  ) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: [
        {
          text: 'OK',
          handler,
        },
      ],
    });
    await alert.present();
  }

  async showAlertYesNo(
    header: string,
    message: string,
    handlerYes: () => void,
    handlerNo: () => void
  ) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: [
        {
          text: 'SIM',
          handler: handlerYes,
        },
        {
          text: 'NÃO',
          handler: handlerNo,
        },
      ],
    });
    await alert.present();
  }
}
