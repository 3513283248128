import { AddressGeolocation } from './address-geolocation';
import { City } from './city';

export class Address {
  address?: string;
  city?: City;
  complement?: string;
  district?: string;
  id?: number;
  numberAddress?: string;
  zipCode?: string;
  addressGeolocation?: AddressGeolocation;

  fromJson(data: any) {
    this.address = data?.address;
    this.city = new City();
    this.city.fromJson(data?.city);
    this.complement = data?.complement;
    this.district = data?.district;
    this.id = data?.id;
    this.numberAddress = data?.numberAddress;
    this.zipCode = data?.zipCode;
    if (data?.AddressGeolocation?.length > 0) {
      this.addressGeolocation = Object.assign(
        new AddressGeolocation(),
        data?.AddressGeolocation.slice()?.shift()
      );
    }
  }

  fromJsonPT(data: any) {
    if (data) {
      this.address = data?.logradouro;
      this.city = new City();
      this.city.fromJsonPT(data);
      this.complement = data?.complemento;
      this.district = data?.bairro;
      this.id = data?.id;
      this.numberAddress = data?.numero;
      this.zipCode = data?.cep.trim().replace('.', '');
    }
  }
}
